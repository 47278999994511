export class AppMenuItem {
  label?: string;
  icon?: string;
  routerLink?: string[];
  items?: AppMenuItem[];
  dontNeedPermission?;
  permission?: string;
  roles?: string[];
  showChilds?: boolean;
}

export class AppMenuModel {
  public static readonly menuHome: AppMenuItem = {
    label: 'menu.home',
    icon: 'pi pi-fw pi-home',
    routerLink: ['/'],
    dontNeedPermission: false
  };

  public static readonly menuEstado: AppMenuItem = {
    label: 'menu.estado',
    icon: 'pi pi-fw pi-home',
    routerLink: ['/estados'],
    dontNeedPermission: true
  };

  public static readonly menuFormaConcessao: AppMenuItem = {
    label: 'menu.forma-concessao',
    icon: 'pi pi-fw pi-tags',
    routerLink: ['/concessoes'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF']
  };

  public static readonly menuAgencia: AppMenuItem = {
    label: 'menu.agencia',
    icon: 'pi pi-fw pi-shield',
    routerLink: ['/agencias'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF']
  };

  public static readonly menuOrigem: AppMenuItem = {
    label: 'menu.origem',
    icon: 'pi pi-fw pi-map-marker',
    routerLink: ['/origens'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF']
  };

  public static readonly menuFornecedor: AppMenuItem = {
    label: 'menu.fornecedor',
    icon: 'pi pi-fw pi-table',
    routerLink: ['/fornecedores'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF']
  };

  public static readonly menuAcaoOrcamentaria: AppMenuItem = {
    label: 'menu.acaoOrcamentaria',
    icon: 'pi pi-fw pi-sitemap',
    routerLink: ['/acoes-orcamentarias'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF']
  };

  public static readonly menuParametro: AppMenuItem = {
    label: 'menu.parametro',
    icon: 'pi pi-fw pi-link',
    routerLink: ['/parametros'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR']
  };

  public static readonly menuNatureza: AppMenuItem = {
    label: 'menu.natureza',
    icon: 'pi pi-fw pi-clone',
    routerLink: ['/naturezas'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF']
  };

  public static readonly menuContaCorrente: AppMenuItem = {
    label: 'menu.conta-corrente',
    icon: 'pi pi-fw pi-wallet',
    routerLink: ['/contas-correntes'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF']
  };

  public static readonly menuStatus: AppMenuItem = {
    label: 'menu.status',
    icon: 'pi pi-fw pi-info-circle',
    routerLink: ['/status'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR']
  };

  public static readonly menuPortaria: AppMenuItem = {
    label: 'menu.portaria',
    icon: 'pi pi-fw pi-align-left',
    routerLink: ['/portarias'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF']
  };

  public static readonly menuSuprido: AppMenuItem = {
    label: 'menu.suprido',
    icon: 'pi pi-fw pi-id-card',
    routerLink: ['/supridos'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF']
  };

  public static readonly menuObjetoDespesa: AppMenuItem = {
    label: 'menu.objeto-despesa',
    icon: 'pi pi-fw pi-ticket',
    routerLink: ['/objetos-despesas'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF']
  };

  public static readonly menuDespesa: AppMenuItem = {
    label: 'menu.despesa',
    icon: 'pi pi-fw pi-dollar',
    routerLink: ['/despesas'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF', 'SUPRIDO']
  };

  public static readonly menuSuprimento: AppMenuItem = {
    label: 'menu.suprimento',
    icon: 'pi pi-fw pi-shopping-cart',
    routerLink: ['/suprimentos'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF', 'SUPRIDO']
  };

  public static readonly menuRelatorioSuprido: AppMenuItem = {
    label: 'relatorio.field.suprido',
    icon: 'pi pi-fw  pi-id-card',
    routerLink: ['/supridos/relatorio'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF']
  };

  public static readonly menuMovimentarSuprimento: AppMenuItem = {
    label: 'menu.movimentar-suprimento',
    icon: 'pi pi-fw pi-send',
    routerLink: ['/movimentar-suprimentos'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF']
  };

  public static readonly menuFinalizarAplicacao: AppMenuItem = {
    label: 'menu.finalizar-aplicacao',
    icon: 'pi pi-fw pi-sign-out',
    routerLink: ['/aplicacao-suprimento'],
    roles: ['ADMINISTRADOR', 'COF', 'SUPRIDO']
  };

  public static readonly menuExcesso: AppMenuItem = {
    label: 'menu.excesso',
    icon: 'pi pi-fw pi-chart-line',
    routerLink: ['/suprimentos/relatorio-excesso'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF', 'SUPRIDO']
  };

  public static readonly menuSuprimentoAplicacao: AppMenuItem = {
    label: 'menu.suprimento-aplicacao',
    icon: 'pi pi-fw pi-circle-off',
    routerLink: ['/suprimentos/relatorio-suprimento-aplicacao'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF']
  };

  public static readonly menuDocFechamento: AppMenuItem = {
    label: 'menu.doc-fechamento',
    icon: 'pi pi-fw pi-sign-out',
    routerLink: ['/aplicacao-suprimento/relatorio-doc-fechamento'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF', 'SUPRIDO']
  };

  public static readonly menuMeusSuprimentos: AppMenuItem = {
    label: 'menu.meu-demonstrativo-receita-despesa',
    icon: 'pi pi-fw pi-table',
    routerLink: ['/aplicacao-suprimento/relatorio-meus-suprimentos'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF', 'SUPRIDO']
  };

  public static readonly menuDemonstrativoReceitaDespesa: AppMenuItem = {
    label: 'menu.demonstrativo-receita-despesa',
    icon: 'pi pi-fw pi-align-justify',
    routerLink: ['/aplicacao-suprimento/relatorio-demonstrativo-receita-despesa'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF']
  };

  public static readonly menuRelatorioSupridoImpedido: AppMenuItem = {
    label: 'menu.suprido-impedido',
    icon: 'pi pi-fw pi-user-minus',
    routerLink: ['/suprimentos/relatorio-suprido-impedido'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF']
  };

  public static readonly menuSuprimentoDetalhado: AppMenuItem = {
    label: 'menu.suprimento-detalhado',
    icon: 'pi pi-fw pi-sliders-h',
    routerLink: ['/suprimentos/relatorio-suprimento-detalhado'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF']
  };

  public static readonly menuRelatorioLogFechamento: AppMenuItem = {
    label: 'menu.relatorio-log-fechamento',
    icon: 'pi pi-fw pi-lock',
    routerLink: ['/aplicacao-suprimento/relatorio-log-fechamento'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF']
  };

  public static readonly menuTodosSuprimentos: AppMenuItem = {
    label: 'menu.todos-suprimentos',
    icon: 'pi pi-fw pi-th-large',
    routerLink: ['/despesas/relatorio-todos-suprimentos'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF']
  };

  public static readonly menuLogSuprimento: AppMenuItem = {
    label: 'menu.log-suprimento',
    icon: 'pi pi-fw pi-book',
    routerLink: ['/suprimentos/relatorio-log-suprimento'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR']
  };

  public static readonly menuAuditoria: AppMenuItem = {
    label: 'menu.auditoria',
    icon: 'pi pi-fw pi-history',
    routerLink: ['/auditoria'],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF']
  };

  public static readonly menuCadastro: AppMenuItem = {
    label: 'menu.cadastro',
    icon: 'pi pi-fw pi-save',
    items: [
      AppMenuModel.menuFormaConcessao,
      AppMenuModel.menuAgencia,
      AppMenuModel.menuFornecedor,
      AppMenuModel.menuNatureza,
      AppMenuModel.menuContaCorrente,
      AppMenuModel.menuAcaoOrcamentaria,
      AppMenuModel.menuOrigem,
      AppMenuModel.menuStatus,
      AppMenuModel.menuSuprido,
      AppMenuModel.menuObjetoDespesa,
      AppMenuModel.menuParametro,
      AppMenuModel.menuPortaria
    ],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF']
  };

  public static readonly menuMovimentacoes: AppMenuItem = {
    label: 'menu.movimentacoes',
    icon: 'pi pi-fw pi-sliders-h',
    items: [
      AppMenuModel.menuMovimentarSuprimento,
      AppMenuModel.menuSuprimento,
      AppMenuModel.menuDespesa,
      AppMenuModel.menuFinalizarAplicacao
    ],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF', 'SUPRIDO']
  };

  public static readonly menuRelatorios: AppMenuItem = {
    label: 'menu.relatorio',
    icon: 'pi pi-fw pi-file-o',
    items: [
      AppMenuModel.menuDocFechamento,
      AppMenuModel.menuRelatorioSuprido,
      AppMenuModel.menuDemonstrativoReceitaDespesa,
      AppMenuModel.menuSuprimentoAplicacao,
      AppMenuModel.menuMeusSuprimentos,
      AppMenuModel.menuRelatorioSupridoImpedido,
      AppMenuModel.menuSuprimentoDetalhado,
      AppMenuModel.menuExcesso,
      AppMenuModel.menuLogSuprimento,
      AppMenuModel.menuRelatorioLogFechamento,
      AppMenuModel.menuTodosSuprimentos,
      AppMenuModel.menuSuprimentoAplicacao,
      AppMenuModel.menuAuditoria
    ],
    dontNeedPermission: false,
    roles: ['ADMINISTRADOR', 'COF', 'SUPRIDO']
  };

  public static readonly menu: AppMenuItem[] = [
    AppMenuModel.menuHome,
    AppMenuModel.menuCadastro,
    AppMenuModel.menuMovimentacoes,
    AppMenuModel.menuRelatorios
  ];
}
